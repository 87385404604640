import React from "react";

export default {
    backend: {
        host: "https://the-politics-of-covid-19.com/search/api",
        index: "cdr"
    },
    header: {
        placeholder: "  Type a word or phrase",
        logo: null,
        title: "Digital Rights Archive",
        intro: <span>{"Search for high-quality materials on digital rights and infrastructure—across text, video and audio, in English and in French. Powered by the curation technology—part-algorithmic, part-human—developed by "}<a target='_blank' rel="noopener noreferrer" href="https://the-syllabus.com/">{"The"}&nbsp;{"Syllabus"}</a>{", and presented with experts from the "}<a target='_blank' rel="noopener noreferrer" href="https://centrefordigitalrights.org/">{"Centre for Digital Rights"}</a>{". Read about the archive "}<a target='_blank' rel="noopener noreferrer" href="//digitalrightsarchive.net">{"here"}</a>{"."}</span>,
        extra_logos: <div className="header_img_syllabus_wrapper"><span className="header_poweredby">Presented with</span><a target='_blank' rel="noopener noreferrer" href="https://centrefordigitalrights.org/"><img className="header_img_syllabus header_img_cdr" src={'img/cdr.png'} alt="Cender for Digital Rights"/></a></div>
    },
    form:{
      formId: "VRXZEF"
    }
};
